export interface Asset {
  id: number
  alt: string
  name: string
  focus: string
  title: string
  source: string
  filename: string
  copyright: string
  fieldtype: 'asset'
  is_external_url: boolean
}

export interface SingleAsset {
  component: 'singleAsset'
  usePlayButton: boolean
  colorOfPlayButton: string
  altText: string
  asset: Asset
}

export interface Link {
  id: string
  url: string
  linktype: string
  fieldtype: 'multilink'
  cached_url: string

  // Added when request with resolve_links is made to Storybook
  story?: {
    full_slug: string
  }
}

export interface SimpleLink {
  label: string
  component: 'simpleLink'
  link: Link
}

export interface CTA {
  label: string
  link: Link
}

export interface SEO {
  seoTitle: string
  seoDescription: string
  component: 'seo'
}

export const EDGE_STYLES = ['none', 'teeth', 'scallop', 'wave'] as const

export type EdgeStyles = typeof EDGE_STYLES[number]

export interface ShortTitle {
  component: 'shortTitle'
  text: string
}

export interface LongTitle {
  component: 'longTitle'
  text: string
}

export interface Palette {
  component: 'palette'
  palette_color: string
}

export interface CustomColor {
  component: 'custom-color'
  custom_color: string
}

export interface CTALabelCategory {
  component: 'cta-label-category'
  cta_label: string
}

export interface CTALabelCampaign {
  component: 'cta-label-campaign'
  cta_label: string
}

export interface TextField {
  component: 'text-field'
  id: string
  name: string
  placeholder: string
  is_email_input_type: boolean
  validation_error: string
  exclude_field_in_tracking: boolean
}

export interface SelectField {
  component: 'select-field'
  id: string
  name: string
  placeholder: string
  options: string
  validation_error: string
  exclude_field_in_tracking: boolean
}
